/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary-color: #8574ec;
  --secondary-color: #ec7674;
  --buriza-white: #f0eeee;
  --buriza-dark: #2b2b2b;
  --secondary-shadow: #e9565625;
}

body {
  font-family: "K2D", sans-serif;
  background-color: var(--buriza-dark);
  color: var(--buriza-white);
}

.background-image {
  background-image: url("./assets/img/cover.svg"); /* Path to your image */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
}

.card {
  object-fit: scale-down;
}

@layer base {
  button {
    @apply bg-burizaRed text-white font-bold py-2 px-4 rounded-md;
  }
  body {
    @apply background-image;
  }
}
